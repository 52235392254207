/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import { serviceTiers } from 'src/dto/eob/servicetiers';
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment, ReferenceType
} from '../dto';


export interface Application_v1 extends DTO, DTOMethods<Application_v1> {
    serviceTier: number;
   // organization: DTORef;
    costCenter: DTORef;
    observabilityRequirements: DTORef[];
    footprints: DTORef[];
    allocations: DTORef[];
    claims: DTORef[];
    inventory: DTORef[];
    content: Attachment[];
    oiiScore: number;
}
export const Application_v1: DTOMetaData & DTOClassMethods<Application_v1> = {
    _label:'Applications',
    _dto: 'Application_v1',
    _type: 'application',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: '', prop: 'refType', hint: '', type: 'number', fixed: true, hidden: true, default: ReferenceType.APPLICATION },
        { label: 'IDX (External Identifier)', prop: 'idx', hint: 'ID of this application', type: 'string', fixed: true, source: null, values: undefined },
        { label: 'Organization', prop: 'organization', hint: 'Organization', type: 'dto', required: true, fixed: true, relatedProp: 'parentId', source: 'Organization_v1', values: undefined },
        { label: 'Cost center', prop: 'costcenter', hint: 'Costcenter', type: 'dto', fixed: true, source: 'Costcenter_v1', values: undefined },
        { label: 'Service Tier', prop: 'serviceTier', hint: 'Service Tier', type: 'enum', fixed: false, source: null, values: serviceTiers },
        { label: 'OII Score', prop: 'oiiScore', hidden: true, hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
     ],
    _related: [
        { prop: 'observabilityRequirements', type: 'observability-requirements', class: 'ObservabilityRequirements_v1', label: 'Requirements' },
        { prop: 'footprints', type: 'footprint', class: 'Footprint_v1', label: 'Deployment Footprints' },
      //  { prop: 'allocations', type: 'observability-allocation', class: 'ObservabilityAllocation_v1', label: 'Allocations' },
      //  { prop: 'claims', type: 'observability-claim', class: 'ObservabilityClaim_v1', label: 'Claims' },
      //  { prop: 'inventory', type: 'observable-inventory', class: 'ObservableInventory_v1', label: 'Inventory Items' }
    ],
    _endpoint: '/api/eob/v1.0/application/v1.0',
    _childrenAt: 'footprints',
    _parentDTONames: 'Organization_v1',
    _docLink: '/wiki/elevate/Assets/application/',
    _icon: './assets/dtos/application.svg',
    _cssClass: "eob-styles",

    endpointAPI: new BoundEndpointAPI<Application_v1>('Application_v1', DTO),
    from: (obj: any): Application_v1 => {
        return Object.setPrototypeOf(obj, Application_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Application_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Application_v1.from(a) as Application_v1)
            : Application_v1.from(res)
    )}
}
